const links = [
    {
        pageId: "overview",
        name: "Calculadora financeira",
        path: "/dashboard/overview"
    },
    {
        pageId: "reports",
        name: "Relatórios",
        path: "/dashboard/reports"
    },
]

export default links