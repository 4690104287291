//Default imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//Services
import { authenticate } from "../../services/user/post";

//Components
import Button from "../../components/button/button";
import Input from "../../components/input/input";

//Icons
import { AiOutlineLogin, AiOutlineMail, AiOutlineUnlock } from "react-icons/ai";

//Image
import Logo from "../../img/logo.png";

//Style
import { toast } from "react-hot-toast";
import toastStyle from "../../data/toast_style";
import "./login.css";

export default function Login() {
  const [data , setData] = useState({
    email: "",
    password: ""
  });
  const [isLoging , setIsloging] = useState(false);
  const navigate = useNavigate()
  
  async function auth() {
    setIsloging(true)
    const toastId = toast.loading("Entrando na conta..." , toastStyle())
    if(data.email.length <6 || !data.email.includes("@")) {
      toast.dismiss(toastId)
      toast.error("Digite um e-mail válido!" , toastStyle());
      return setIsloging(false)
    }
    const _auth = await authenticate(data);
    if(_auth.code == 200) {
      localStorage.setItem("@SM:Token" , _auth.data.token);
      localStorage.setItem("@SM:Id" , _auth.data.user_id);
      return window.location.href = "/dashboard/overview"
    }
    setIsloging(false)
    toast.dismiss(toastId)
    toast((t) => {
      return(
        <span className="toast_dimiss">
          {_auth?.message} 
          <button  className="toast_button" onClick={() => toast.dismiss(t.id)}>Fechar</button>
        </span>
      )
    } , toastStyle());
  }
    return (
        <div class="container">
        <div class="left">
          <img className="animation a1" src={Logo} width={"212px"} />
        <div className="horizontal_line_dash"></div>
        <div className="call">
          <h2 class="animation a1">Controle financeiro pessoal,<br/> simples e sem frescura</h2>
        </div>
        <div className="horizontal_line"></div>
          <div class="header_login">
            <h2 class="animation a1">Bem-vindo</h2>
            <h4 class="animation a2">Ainda não tem uma conta ? <span onClick={() => {
              window.location.href = "/singup"
            }}>Cadastre-se agora</span></h4>
          </div>
          <div className="horizontal_line"></div>
          <div class="form">
            <Input 
            type={"normal"}
            disable={isLoging}
            typeInput={"e-mail"}
            title={"E-mail"}
            placeholder={"Ex: exemple@mail.com"}
            icon={<AiOutlineMail />}
            margin={"15px 0px 10px 0px"}
            searchValue={value => {
                setData({
                  ...data , email: value
                })
            }}
            background={"white"}
            mainStyle={{
              opacity: isLoging ? "0.4" : "1",
              cursor: isLoging ? "not-allowed" : "unset"
            }}
            />
            <Input 
            type={"password"}
            disable={isLoging}
            title={"Senha"}
            placeholder={"***********"}
            icon={<AiOutlineUnlock />}
            margin={"0px 0px 15px 0px"}
            searchValue={value => {
                setData({
                  ...data , password: value
                })
            }}
            onKey={(e) => {
              if(e == "Enter") {
                auth()
              }
            }}
            mainStyle={{
              opacity: isLoging ? "0.4" : "1",
              cursor: isLoging ? "not-allowed" : "unset"
            }}
            />
            <div className="forgot_container">
              <p>Esqueceu a sua senha? <span onClick={() => {
                navigate("/remember")
              }}>Clique aqui</span></p>
            </div>
            <Button 
            action={() => {auth()}}
            text={"Entrar agora"}
            type={"default"}
            icon={<AiOutlineLogin />}
            fontSize={"15px"}
            radius={"35px"}
            height={"35px"}
            disable={isLoging}
            />
          </div>
        </div>
        <div class="right"></div>
      </div>
    )
}