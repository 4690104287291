//Api
import environment from "../../environment/environment";

//Utils
import translate from "../../utils/translate";


export async function myUser() {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");

    const _get = await fetch(`${environment.DB_CONNECT}/${environment.API_VERSION}/user/${id}` , {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        },
    });

    const _data = await _get.json();
    if(_get.status != 200) {
        return {
            code: _get.status,
            message: translate(_data.message)
        }
    }
    if(_get.status == 200) {
        return {
            code: _get.status,
            data: _data
        }
    }
};
export async function mySpecificUser() {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");

    const _get = await fetch(`${environment.DB_CONNECT}/${environment.API_VERSION}/user/specific/${id}` , {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        },
    });

    const _data = await _get.json();
    if(_get.status != 200) {
        return {
            code: _get.status,
            message: translate(_data.message)
        }
    }
    if(_get.status == 200) {
        return {
            code: _get.status,
            data: _data
        }
    }
};

export async function myReports() {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");

    const _get = await fetch(`${environment.DB_CONNECT}/${environment.API_VERSION}/reports/${id}` , {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-access-token": token
        },
    });

    const _data = await _get.json();
    if(_get.status != 200) {
        return {
            code: _get.status,
            message: translate(_data.message)
        }
    }
    if(_get.status == 200) {
        return {
            code: _get.status,
            data: _data
        }
    }
}