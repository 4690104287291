export function moneyFormat(numero) {
    // Converte o número para string e adiciona zeros à esquerda, se necessário
    const numeroFormatado = Number(numero).toFixed(2);
  
    // Adiciona separador de milhares e de decimais
    const partes = numeroFormatado.split('.');
    const parteInteira = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const parteDecimal = partes[1];
  
    // Retorna o número formatado como dinheiro
    return `R$${parteInteira}.${parteDecimal}`;
  }