export default function toastStyle(emoji) {
      return {
        icon: emoji && emoji,
        style: {
          borderRadius: "35px",
          background: "white",
          border: "1px solid var(--line)",
          boxShadow: "none",
          fontSize: "14px",
          margin: "0"
        },
      };

  }