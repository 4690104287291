import { MdAdd } from "react-icons/md";


export default function AddButton({setpopup}) {
    return (
        <div onClick={() => {setpopup("CREATE")}} className="add_button"
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            bottom: "0",
            left: "calc(50% - 100px)",
            width: "200px",
            height: "100px",
            backgroundColor: "var(--main-green-2)",
            borderRadius: "300px 300px 0px 0px",
            color: "white",
            fontSize: "20px",
            fontWeight: "700",
            textAlign: "center",
            cursor: "pointer",
            zIndex: "15"
        }}
        >
            <MdAdd size={"2.5em"} color="white" />
            Adicionar
        </div>
    )
}