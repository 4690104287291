//Default imports
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";

//Services
import { deleteReport } from "../../../services/user/delete";
import { myReports } from "../../../services/user/get";

//Components
import Header from "../../../components/header/header";
import { ListCards, Loading } from "../overview/overview";
import { Edit } from "../overview/popups/edit";

//Data
import { months, years } from "../../../data/months";

//Icons
import { MdCalendarMonth, MdDelete, MdEdit, MdLoop, MdMoney, MdOutlineCalendarMonth, MdOutlineDashboard, MdOutlineWarningAmber, MdSearch } from "react-icons/md";
import { TbArrowsDownUp } from "react-icons/tb";

//Image
import Calc from "../../../img/boneco.png";

//Style
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import toastStyle from "../../../data/toast_style";
import { editPay } from "../../../services/user/put";
import "../../../styles/default_table.css";
import { moneyFormat } from "../../../utils/money_format";
import "./report.css";


export default function Reports({pageId}) {
    const [apidata , setApidata] = useState([]);
    const [filterdata , setFilterData] = useState([]);
    const [status , setStatus] = useState(true);
    const [selectDay , setSelectDay] = useState(null);
    const [selectmonth , setSelectmonth] = useState(new Date().getMonth() + 1);
    const [selectyear , setSelectYear] = useState(new Date().getFullYear())
    const [positivedata , setPositivedata] = useState([]);
    const [negativedata , setNegativedata] = useState([]);
    const [total , setTotal] = useState(0);
    const [revenue , setRevenue] = useState(0);
    const [expense , setExpense] = useState(0);
    const [balance , setBalance] = useState(0);
    const [percent , setPercent] = useState(0);
    const [chart , setChart] = useState([]);
    const [search , setSearch] = useState("");
    const [reload , setReload] = useState(false)
    const [loading , setLoading] = useState(true);
    const [typefilter , setTypefilter] = useState("REVENUE");
    const [reportSelect , setReportSelect] = useState(null);
    const [popup, setPopup] = useState("")

    function enterNumbers(numero, limiteInferior, limiteSuperior) {
        return numero >= limiteInferior && numero <= limiteSuperior;
      }
      function filtering(data) {
        setPositivedata([]);
        setNegativedata([]);
        var totals = 0;
        var revalue = 0;
        var expenses = 0;
        var balances = 0;
        var percents = 0;
        setTotal(0);
        setRevenue(0);
        setExpense(0);
        setBalance(0);
        setPercent(0);
        const newData = [];
        for(let i = 0 ; i < data?.length ; i++) {
            const monthl = new Date(data[i]?.date).getMonth();
            const year = new Date(data[i]?.date).getFullYear();
            const _find = newData?.sort((a , b) => a.realDate <= b.realDate ? -1 : 1).find((res) => res?.month === monthl)
            if(_find) {
                if(data[i]?.type == "revenue") {
                    _find.Receita += parseInt(data[i].value / 100 , 10)
                }
                if(data[i]?.type == "expense" && data[i]?.is_received == "expired" ) {
                    _find.Despesa += parseInt(data[i].value / 100 , 10)
                }
            } else {
                newData.push({
                    realDate: data[i]?.date,
                    month: monthl,
                    monthMask: `${months[monthl]?.abbreviation} (${year})`,
                    Receita: data[i]?.type === "revenue" ? parseInt((data[i]?.value / 100), 10) : 0,
                    revenueColor: "#0180FF",
                    Despesa: data[i]?.type === "expense" && data[i]?.is_received == "expired" ? parseInt(data[i]?.value / 100, 10) : 0,
                    expenseColor: "#0180FF",
                });
            }
        };
        setChart(newData)
        for(var newdata of data) {
            const month = new Date(newdata?.date).getMonth() + 1;
            const year = new Date(newdata?.date).getFullYear();
            const withPortion = new Date(newdata?.date).getMonth() + newdata?.portion
            if(!newdata.fixed) {
                if(newdata.portion != 1) {
                    if(enterNumbers(selectmonth , month , withPortion)  && year == selectyear && newdata.type == "revenue") {
                        revalue = revalue + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0));
                        setRevenue(revalue)
                    }
                    if(enterNumbers(selectmonth , month , withPortion)  && year == selectyear && newdata.type == "expense" && newdata?.is_received == "expired") {
                        expenses = expenses + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0))
                        setExpense(expenses)
                    }
                    if(enterNumbers(selectmonth , month , withPortion)  && year == selectyear) {
                        totals = totals + newdata.value;
                        balances = revalue - expenses;
                        setTotal(totals)
                        setBalance(balances);
                        setPercent(percents)
                    }
                }
                if(newdata.portion == 1) {
                    if(month == selectmonth  && year == selectyear && newdata.type == "revenue") {
                        revalue = revalue + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0));
                        setRevenue(revalue)
                    }
                    if(month == selectmonth  && year == selectyear && newdata.type == "expense" && newdata?.is_received == "expired") {
                        expenses = expenses + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0))
                        setExpense(expenses)
                    }
                    if(month == selectmonth  && year == selectyear) {
                        totals = totals + newdata.value;
                        balances = revalue - expenses;
                        setTotal(totals)
                        setBalance(balances);
                        setPercent(percents)
                    }
                }
            }
            if(newdata.fixed) {
                    if(newdata.type == "revenue") {
                        revalue = revalue + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0));
                        setRevenue(revalue)
                    }
                    if( newdata.type == "expense" && newdata?.is_received == "expired") {
                        expenses = expenses + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0))
                        setExpense(expenses)
                    }
                        totals = totals + newdata.value;
                        balances = revalue - expenses;
                        setTotal(totals)
                        setBalance(balances);
                        setPercent(percents)
                    

            }
        }

        data.filter((res) => {
            const month = new Date(res?.date).getMonth() + 1;
            const withPortion = new Date(res?.date).getMonth() + res?.portion
            const year = new Date(res?.date).getFullYear();
            if(res?.portion == 1 && !res?.fixed) {
                if(month == selectmonth && year == selectyear && res.type == "revenue") {
                    setPositivedata(positivedata => [...positivedata , res]);
                }
                if(month == selectmonth && year == selectyear && res.type == "expense") {
                    setNegativedata(negativedata => [...negativedata , res])
                }
            }
            if(res?.portion != 1 && !res?.fixed) {
                if(enterNumbers(selectmonth , month , withPortion) && year == selectyear && res.type == "revenue") {
                    setPositivedata(positivedata => [...positivedata , res]);
                }
                if(enterNumbers(selectmonth , month , withPortion) && year == selectyear && res.type == "expense" ) {
                    setNegativedata(negativedata => [...negativedata , res])
                }
            }
            if(res?.fixed) {
                if(res.type == "revenue") {
                    setPositivedata(positivedata => [...positivedata , res]);
                }
                if(res.type == "expense" ) {
                    setNegativedata(negativedata => [...negativedata , res])
                }
            }
        })
    };
    function filterList(data) {
        setFilterData(data?.filter((res) => {
            const month = new Date(res?.date).getMonth() + 1;
            const year = new Date(res?.date).getFullYear();
            const day = new Date(res?.date).getDate();
            if(selectDay == null) {
                if(month == selectmonth && year == selectyear && res.name.toLowerCase().includes(search.toLocaleLowerCase())) {
                    return res
                }
            }
            if(selectDay != null) {
                if(day == selectDay && month == selectmonth && year == selectyear && res.name.toLowerCase().includes(search.toLocaleLowerCase())) {
                    return res
                }
            }
        }))
    }
    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const _get = await myReports();
            setFilterData([])
            setApidata(_get.data?.data);
            if(_get) {
                setReload(false)
            }
            setTimeout(() => {
                setLoading(false)
            } , 1000)
        }
        fetchData()
    } , [reload , setReload])
    useEffect(() => {
        if(apidata.length != 0) {
            filtering(apidata);
        }
    } , [selectmonth , selectyear , selectDay , apidata ,search]);
    useEffect(() => {
        if(apidata.length != 0) {
            filterList(apidata)
        }
    } , [selectmonth , selectyear , apidata , search , selectDay]);
    useEffect(() => {
        if(balance < 0) {
            setStatus(false)
        };
        if(balance >= 0) {
            setStatus(true)
        }
    } , [balance]);

    useEffect(() => {
        if(reportSelect != null) {
        setPopup("EDIT")
        }
    } , [reportSelect])

    async function reportDelete(id) {
        const toastId = toast.loading("Deletando finança..."  , toastStyle())
        const _delete = await deleteReport(id);
        if(_delete.code == 200) {
            toast.dismiss(toastId)
           toast.success("Finança deletada!" , toastStyle());
           return setReload(true)
        }
        if(_delete.code != 200) {
            toast.dismiss(toastId)
            toast.error("Erro ao deletar." , toastStyle())
            return setReload(true)
        }
    }
    async function pay(value , id , type) {
        const toastId = toast.loading("Alterando finança..."  , toastStyle());
        const _pay = await editPay(value , id);
        if(_pay.code == 200) {
            toast.dismiss(toastId)
            if(type == "expired") {
                toast.success("Finança retirada!" , toastStyle());
            }
            if(type == "pay") {
                toast.success("Finança paga!" , toastStyle());
            }
           return setReload(true)
        }
        if(_pay.code != 200) {
            toast.dismiss(toastId)
            toast.error("Erro ao deletar." , toastStyle())
            return setReload(true)
        }
    }
    return(
        <div className="reports" style={{
            overflowY: popup == "EDIT" || popup == "CREATE" ? "hidden" : "auto"
        }}>
            {loading && <Loading />}
            {popup == "EDIT" && <Edit setRevenue={setReportSelect} setPopup={setPopup} setReload={setReload} revenue={reportSelect} />}
            <Header pageId={pageId} />
            <div className="report_bar">
                <div className="report_limitation">
                    <div className="info_part">
                        <div className="info_item">
                            <div className="icon_container">
                                <MdOutlineDashboard size={"1.5em"} color="var(--font-thin)" />
                            </div>
                            <div className="text_info">
                                <p>Receita Total</p>
                                <h3>{moneyFormat(revenue / 100)}</h3>
                            </div>
                        </div>
                        <div className="vertical_line" style={{height: "80px"}}></div>
                        <div className="info_item">
                            <div className="icon_container">
                                <MdOutlineDashboard size={"1.5em"} color="var(--font-thin)" />
                            </div>
                            <div className="text_info">
                                <p>Despesas Total</p>
                                <h3>{moneyFormat(expense / 100)}</h3>
                            </div>
                        </div>
                        <div className="vertical_line" style={{height: "80px"}}></div>
                        <div className="info_item">
                            <div className="icon_container">
                                <MdOutlineDashboard size={"1.5em"} color="var(--font-thin)" />
                            </div>
                            <div className="text_info">
                                <p>Saldo Total</p>
                                <h3>{moneyFormat(balance / 100)}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="percent">
                        <img src={Calc}/>
                        <div className="content_percent">
                            <h3>Porcentagem Mensal</h3>
                            <p>Adicione, compare e impulsione as suas finanças mensais</p>
                            <div className="horizontal_line"></div>
                            <div className="bar_per">
                                <div className="percent_bar">
                                    <div className="actual_percent" style={{
                                        width: `${percent}%`,
                                        justifyContent: percent < 5 || !isFinite(percent) ? "flex-start" : "flex-end"
                                    }}>
                                    </div>
                                    <h3
                                    style={{
                                        color: percent < 10 ? "var(--font-thin)": "white"
                                    }}
                                    ><span style={{fontSize:"14px"}}>({isFinite(percent) ?  
                                        percent : 0}%)</span></h3>
                                </div>
                                <h4>{moneyFormat(revenue / 100)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="filter_bar">
                        <div className="filter_limitation">
                            <div className="p1_filter">
                                <div className="month_info">
                                    <div className="icon_container">
                                        <MdCalendarMonth size={"2em"} color="var(--font-thin)" />
                                    </div>
                                    <div className="text_month">
                                        <h3>{months[selectmonth - 1]?.month}</h3>
                                        <p>Receita e despesas</p>
                                    </div>
                                </div>
                                <div className="filter">
                                    <div className="select_input">
                                        <MdOutlineCalendarMonth size={"1.2em"} />
                                        <select onChange={(e) => {
                                            months.filter((res) => {
                                                if(res.month == e.target.value) {
                                                    setSelectmonth(res.id)
                                                }
                                            })
                                        }} value={months[selectmonth - 1]?.month}>
                                            {months?.map((res , i) => {
                                                return (
                                                    <option key={res.id}>{res.month}</option>
                                                )
                                            })}
                                        </select>
                                        <TbArrowsDownUp />
                                    </div>
                                    <p>De</p>
                                    <div className="select_input">
                                        <MdOutlineCalendarMonth size={"1.2em"} />
                                        <select onChange={(e) => {
                                            setSelectYear(e.target.value)
                                        }} value={selectyear}>
                                            {years?.map((res , i) => {
                                                return (
                                                    <option key={res}>{res}</option>
                                                )
                                            })}
                                        </select>
                                        <TbArrowsDownUp />
                                    </div>
                                </div>
                            </div>
                            <div className="p2_filter">
                                <div className="filter_select">
                                    <div onClick={() => {setTypefilter("REVENUE")}} className={`filter_button ${typefilter == "REVENUE" && "select"}`}>
                                        Receita
                                    </div>
                                    <div onClick={() => {setTypefilter("EXPENSE")}} className={`filter_button ${typefilter == "EXPENSE" && "select"}`}>
                                        Despesa
                                    </div>
                                </div>
                                <div className="search_bar">
                                    <input value={search} onChange={(e) => {
                                        setSearch(e.target.value)
                                    }} placeholder="Pesquisar relatório" type="text" />
                                    <MdSearch />
                                </div>
                            </div>
                        </div>
            </div>
            <div className={`report_list`} >
                {isMobile && (
                    <ListCards title={"Relatórios"} data={filterdata}  />
                )}
                {!isMobile && (
                <div className="list_limitation">
                    {(typefilter == "REVENUE" ? positivedata : negativedata)?.map((res , i) => {
                        return (
                            <div key={i} className="report_item">
                                <div className="p1_report">
                                    <div className="report_name">
                                        <p>Nome</p>
                                        <h3>{res?.name}</h3>
                                    </div>
                                    <div className="report_infos">
                                        <div className="report_info">
                                            <div className="icon_container">
                                                <MdMoney size={"1.4em"} />
                                            </div>
                                            <div className="texts">
                                                <h3>{moneyFormat(res?.value / 100)}</h3>
                                                <p>Valor total</p>
                                            </div>
                                        </div>
                                        <div className="report_info">
                                            <div className="icon_container">
                                                <MdCalendarMonth size={"1.4em"} />
                                            </div>
                                            <div className="texts">
                                                <h3>{new Date(res?.date).toLocaleDateString()}</h3>
                                                <p>Data</p>
                                            </div>
                                        </div>
                                        <div className="report_info">
                                            <div className="icon_container">
                                                <MdLoop size={"1.4em"} />
                                            </div>
                                            <div className="texts">
                                                <h3>{res.fixed ? "Sim" : "Não"}</h3>
                                                <p>Recorrente</p>
                                            </div>
                                        </div>
                                        {res?.type == "expense" &&(
                                        <div className="report_info">
                                            <div className="icon_container">
                                                <MdOutlineWarningAmber size={"1.3em"} />
                                            </div>
                                            <div className="texts">
                                                <h3>
                                                {
                                                    {
                                                        "pay" : "Pago",
                                                        "expired" : "A pagar",
                                                    }[res.is_received || "Outro"]
                                                }
                                                </h3>
                                                <p>Status</p>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                <div className="p2_report">
                                {res?.type == "expense" && res?.is_received == "expired" ? (
                                <div onClick={() => {
                                        pay("pay" , res?._id , "pay")
                                    }} className="tool_item like">
                                        Pagar
                                        <AiFillLike />
                                    </div>
                                ) : null}
                                {res?.type == "expense"&& res?.is_received == "pay"  ? (
                                <div onClick={() => {
                                        pay("expired" , res?._id , "expired")
                                    }} className="tool_item dislike">
                                        Retirar
                                        <AiFillDislike />
                                    </div>
                                ) : null}
                                <div onClick={() => {
                                        setReportSelect(res)
                                    }} className="tool_item">
                                        <MdEdit />
                                    </div>
                                    <div onClick={() => {
                                        reportDelete(res?._id)
                                    }} className="tool_item delete">
                                        <MdDelete />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                )}
            </div>
        </div>
    )
}