//Defaults
import { useEffect, useState } from "react";

//Utils
import { formatNewphone } from "../../utils/phone_format.js";

//Icons
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

//Style
import "./input.css";

export default function Input({type,mainStyle, fontSize ,fontWeight , background,max,min ,padding , value, list ,sort, defaultvalue , default_select,name,onKey,onBlur, register , typeInput ,title , errtxt , placeholder  , searchValue , disable , icon , width , height , margin}) {
    const [text , setText] = useState("");
    const [txterr , setTxterr] = useState(errtxt)
    const [showpassword , setShowpassword] = useState(false);
    const [phoneNumber , setPhoneNumber] = useState({
        format_number: ""
    })
    useEffect(() => {
        setTxterr(errtxt)
    } , [errtxt]);
    useEffect(() => {
        if(defaultvalue || value) {
            setText(value)
        }
    } , [defaultvalue ,  value]);
    function compare(a , b) {
        if(sort) {
            if(a[`${sort}`] < b[`${sort}`]) {
                return -1
            } else {return 1}
        }
        if(!sort) {
            if(a.name < b.name) {
                return -1
            } else {return 1}
        }
    }
    return (
        <>
            {type == "normal" && (
                <div 
                style={{
                    margin,
                    width: width ? width : "100%",
                    ...mainStyle
                }}
                className="input_container">
                    <p style={{
                        fontWeight: fontWeight ? fontWeight : "600",
                        fontSize: fontSize ? fontSize : "16px"
                    }}>{title}</p>
                    <div 
                    style={{
                        height: height ? height : "40px",
                        borderColor: txterr && "red",
                        padding: padding ? padding : "0px 15px",
                        backgroundColor: background? background :  "transparent"
                    }}
                    className="input">
                        {icon && (
                        <div className="icon_box">
                            {icon}
                        </div>
                        )}
                        <input 
                        type={typeInput}
                        value={value && value}
                        placeholder={placeholder}
                        disabled={disable ? disable : false}
                        {...register}
                        name={name}
                        maxLength={max && max}
                        onKeyDown={(e) => {
                            if(onKey) {
                                onKey(e.key)
                            }
                        }}
                        onBlur={(e) => {
                            if(onBlur) return onBlur(e.target.value)
                        }}
                        onChange={(e) => {
                            setText(e.target.value);
                            setTxterr(false)
                            if(searchValue){
                                if(max) {
                                    if(searchValue.length < max) {
                                        return searchValue(e.target.value)
                                    }
                                }
                                if(!max) {
                                    return searchValue(e.target.value)
                                }
                            }
                        }}
                        />
                    </div>
                    {txterr && <p>{errtxt}</p>}
                    {max && (
                    <div className="limitator_max">
                        {min && <p>Mín: {min}</p>}
                        <p>{text.length}/{max}</p>
                        </div>
                    )}
                </div>
            )}
            {type == "password" && (
            <div 
            style={{
                margin,
                width: width ? width : "100%",
                ...mainStyle
            }}
            className="input_container">
                <p>{title}</p>
                <div 
                style={{
                    height: height ? height : "40px",
                    borderColor: txterr && "red"
                }}
                className="input">
                    <div className="icon_box">
                            {icon}
                        </div>
                    <input 
                    type={showpassword ? "text" : "password"}
                    value={value ? value : text}
                    placeholder={placeholder}
                    disabled={disable ? disable : false}
                    {...register}
                    name={name}
                    onBlur={(e) => {
                        if(onBlur) return onBlur(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if(onKey) {
                            onKey(e.key)
                        }
                    }}
                    onChange={(e) => {
                        setText(e.target.value);
                        setTxterr(false)
                        if(searchValue){
                            searchValue(e.target.value)
                        }
                    }}
                    />
                    {!showpassword && 
                    <AiOutlineEye 
                    size={"1.4em"} 
                    style={{cursor: "pointer"}} 
                    onClick={() => {
                        setShowpassword(true)
                    }}
                    />}
                    {showpassword && 
                    <AiOutlineEyeInvisible 
                    size={"1.4em"} 
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        setShowpassword(false)
                    }}
                    />}
                </div>
                {txterr && <p>{errtxt}</p>}
                {max && (
                    <div className="limitator_max">
                        {min && <p>Mín: {min}</p>}
                        <p>{text.length}/{max}</p>
                    </div>
                )}
            </div>
            )}
            {type == "phone" && (
                <div 
                style={{
                    margin,
                    width: width ? width : "100%",
                }}
                className="input_container">
                    <p>{title}</p>
                    <div 
                    style={{
                        height: height ? height : "40px",
                        borderColor: txterr && "red"
                    }}
                    className="input">
                        <div className="icon_box">
                            {icon}
                        </div>
                        <input 
                        value={value ? formatNewphone(value , 7 , max ) : phoneNumber.format_number}
                        placeholder={placeholder}
                        disabled={disable ? disable : false}
                        {...register}
                        name={name}
                        onKeyDown={(e) => {
                            if(onKey) {
                                onKey(e.key)
                            }
                        }}
                        onBlur={(e) => {
                            if(onBlur) return onBlur(e.target.value)
                        }}
                        onChange={(e) => {
                            setPhoneNumber({
                                ...phoneNumber,
                                format_number: formatNewphone(e.target.value , 7 , max ),
                            });
                            if(searchValue){
                                searchValue( e.target.value.toString().replace(/[^\d]/g, "").substring(0 , max))
                            }
                        }}
                        />
                    </div>
                    {txterr && <p>{errtxt}</p>}
                </div>
            )}
            {type == "textarea" && (
                <div 
                style={{
                    margin,
                    width: width ? width : "100%",
                }}
                className="input_container">
                <textarea 
                value={value ? value : text}
                maxLength={max && max}
                placeholder={placeholder}
                onChange={(e) => {
                    setText(e.target.value)
                    if(searchValue){
                        searchValue(e.target.value)
                    }
                }}
                name={name}
                />
                {max && (
                    <div className="limitator_max">
                        {min && <p>Mín: {min}</p>}
                        <p>{text.length}/{max}</p>
                    </div>
                )}
                </div>
            )}
            {type == "select" && (
                <div 
                style={{
                    margin,
                    width: width ? width : "100%",
                }}
                className="input_container">
                    <p>{title}</p>
                    <select
                    style={{
                        height: height ? height : "40px"
                    }}
                    onChange={(e) => {
                        if(searchValue) return searchValue(e.target.value)
                    }}
                    value={value && value}
                    placeholder={placeholder}
                    defaultValue={default_select}>
                        {default_select && (
                            <option disabled>{default_select}</option>
                        )}
                        {list?.sort(compare).map((res , i) => {
                            return(
                                <option key={res.id}>{res.name}</option>
                            )
                        })}
                    </select>
                </div>
            )}
        </>
    )
}