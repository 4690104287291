//Default imports
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//Contexts
import { AuthContext } from "../../providers/authProvider";

//Components
import Button from "../button/button";

//Utils
import links from "./utils/links";

//Icons
import { MdArrowOutward, MdClose, MdKeyboardArrowDown, MdLogout, MdMenu, MdSettings, MdWhatsapp } from "react-icons/md";

//Images
import Logo from "../../img/logo.png";
import playStore from "../../img/playStore.png";

//Style
import "./header.css";

function logout() {
    localStorage.removeItem("@SM:Token");
    localStorage.removeItem("@SM:Id");
    window.location.href = "/login"
}

export function AppPopup() {
    const navigate = useNavigate()
    return (
        <div className="app_popup">
            <div className="button_app">
                <img src={playStore} />
                <a href="https://play.google.com/store/apps/details?id=com.simplesmoney&hl=pt"  target={"_blank"} className="arrow">
                    <MdArrowOutward />
                </a>
            </div>
            {/*<div className="button_app">
                <img src={appStore} />
                <a href="" className="arrow">
                    <MdArrowOutward />
                </a>
            </div>*/}
        </div>
    )
}

export function MobileMenu({pageId , setMobile , user}) {
    return (
        <div className="background_mobile">
            <div className="mobile_menu">
                <div className="top_mobile">
                    <MdClose onClick={() => {
                        return setMobile(false)
                    }} size={"1.7em"} />
                </div>
                <div className="horizontal_line"></div>
                <div className="logo">
                    <img src={Logo} width={"150px"} /> 
                    <Button 
                    text={"Suporte via WhatsApp"}
                    action={() => {
                        window.open("https://wa.me/5519989873232?text=Gostaria+de+tirar+uma+d%C3%BAvida+sobre+o+Simples+Money%21" , "_blank")
                    }}
                    icon={<MdWhatsapp size={"1.3em"} />}
                    widht={"100%"}
                    height={"36px"}
                    margin={"10px 0px 0px 0px"}
                    fontSize={"16px"}
                    fontWeight={"500"}
                    backgroundColor={"#25d366 "}
                    />
                </div>
                <div className="horizontal_line"></div>
                <div className="apps_icons">
                    <a href="https://play.google.com/store/apps/details?id=com.simplesmoney&hl=pt" target="_blank">
                        <img src={playStore}  />
                    </a>
                </div>
                <div className="horizontal_line"></div>
                <div className="tool_bar_mobile">
                    {links?.map((res, i ) => {
                        return (
                            <Link key={res.pageId} to={res.path} className={`mobile_item ${res.pageId == pageId && "select"}` }>
                                <p>{res.name}</p>
                            </Link>
                        )
                    })}
                </div>
                <div className="horizontal_line"></div>
                <div className="tool_bar_mobile">
                    <Link key={"config"} to={"/dashboard/config"} className={`mobile_item ${pageId == "config" && "select"}` }>
                        <p>Configurações</p>
                    </Link>
                </div>
                <div className="horizontal_line"></div>
                <div className="action_button">
                    <div onClick={logout} className="logout_button">
                        <MdLogout />
                        <p>Desconectar</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function AccountPopup({user}){
    return (
        <div className="account_popup">
            <div className="content_img">
                <div className="write_container">
                    {user?.name?.substring(0,1).toUpperCase()}
                </div>
                <h3>{user?.name}</h3>
                <p>{user?._id}</p>
                <Button 
                text={"Suporte via WhatsApp"}
                action={() => {
                    window.open("https://wa.me/5519989873232?text=Gostaria+de+tirar+uma+d%C3%BAvida+sobre+o+Simples+Money%21" , "_blank")
                }}
                icon={<MdWhatsapp />}
                height={"32px"}
                margin={"10px 0px 0px 0px"}
                fontSize={"14px"}
                fontWeight={"500"}
                />
            </div>
            <div className="horizontal_line"></div>
            <div onClick={logout} className="logout_button">
                <p>Sair da conta</p>
                <MdLogout />
            </div>
        </div>
    )
}

export default function Header({pageId}){
    const [popup , setPopup] = useState("");
    const [mobile , setMobile] = useState(false);
    const [user , setUser] = useState([]);
    const [loading , setLoading] = useState(true);
    const {userLogged} = useContext(AuthContext)
    const navigate = useNavigate();

    return (
        <header className="header">
            {mobile && <MobileMenu pageId={pageId} setMobile={setMobile} user={userLogged?.data} />}
            <div className="header_limitation">
                <div className="p1">
                    <Link to={"/dashboard/overview"} className="logo">
                        <img src={Logo} width={"117px"} />
                    </Link>
                    <div className="vertical_line"></div>
                    <div className="tools">
                        {links?.map((res , i) => {
                            return (
                                <Link to={res.path} key={res.pageId} className={`link_item ${pageId == res.pageId ? "select" : "unselect"}`}>
                                    <p>{res.name}</p>
                                </Link>
                            )
                        })}
                    </div>
                </div>
                <div className="p2">
                    <div className="app_button">
                        <button onClick={() => {
                            if(popup == "APP") return setPopup("")
                            if(popup != "APP") return setPopup("APP")
                        }}>
                            Baixar aplicativo
                            <MdKeyboardArrowDown size={"1.2em"} style={{
                                transform: popup == "APP" ? "rotate(180deg)" : "rotate(0deg)",
                                transition: ".1s ease-in-out"
                            }} />
                        </button>
                        {popup == "APP" && <AppPopup />}
                    </div>
                    <div onClick={() => {
                        navigate("/dashboard/config")
                    }} className="config_button">
                        <MdSettings color="#8C8C8C" size={"1.2em"} />
                    </div>
                    <div className="profile_tool">
                        {popup == "ACCOUNT" && <AccountPopup user={userLogged?.data} />}
                        <div className="container_whrite">
                            {userLogged?.data?.name?.substring(0 , 1).toUpperCase()}
                        </div>
                        <div className="text" onClick={() => {
                        if(popup == "ACCOUNT") return setPopup("")
                        if(popup != "ACCOUNT") return setPopup("ACCOUNT")
                    }}>
                            <h3>{userLogged?.data?.name}</h3>
                            <p>{userLogged?.data?._id}</p>
                        </div>
                        <MdKeyboardArrowDown  onClick={() => {
                        if(popup == "ACCOUNT") return setPopup("")
                        if(popup != "ACCOUNT") return setPopup("ACCOUNT")
                    }} 
                    style={{
                        transform: popup == "ACCOUNT" ? "rotate(180deg)" : "rotate(0deg)",
                        transition: ".1s ease-in-out",
                        cursor: "pointer"
                    }}
                    />
                    </div>
                </div>
                <div onClick={() => {
                    setMobile(true)
                }} className="menu_icon">
                    <MdMenu size={"1.3em"} color="var(--font-bold)" />
                </div>
            </div>
        </header>
    )
}