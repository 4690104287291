//Api
import environment from "../../environment/environment";

//Utils
import translate from "../../utils/translate";

export async function deleteReport(reportId) {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");
    const _delete = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/delete/${reportId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token" :  token
            },
            body: JSON.stringify({userId: id})
        }
    );
    const _data = await _delete.json();
    if(_delete.status != 200) {
        return {
            code: _delete.status,
            message: translate(_data.message)
        }
    }
    if(_delete.status == 200) {
        return {
            code: _delete.status,
            data: _data
        }
    }
};

export async function deleteUser() {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");
    const _delete = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/user/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "x-access-token" :  token
            },
        }
    );
    const _data = await _delete.json();
    if(_delete.status != 200) {
        return {
            code: _delete.status,
            message: translate(_data.message)
        }
    }
    if(_delete.status == 200) {
        return {
            code: _delete.status,
            data: _data
        }
    }
};