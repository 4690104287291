//Default imports
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

//Providers
import { Providers } from "../providers";

//Other Pages
import Login from "../pages/login/login";
import Remember from "../pages/remember/remember";
import Singup from "../pages/singup/singup";

//Dashboard Pages
import Config from "../pages/dashboard/config/config";
import Overview from "../pages/dashboard/overview/overview";
import Reports from "../pages/dashboard/report/report";

export default function Router() {
    return (
        <Providers>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/singup" element={<Singup />} />
                <Route path="/remember" element={<Remember />} />

                {/*Dashboard Routes*/}
                <Route path="/dashboard/overview" element={<Overview pageId={"overview"} />} />
                <Route path="/dashboard/reports" element={<Reports pageId={"reports"} />} />
                <Route path="/dashboard/config" element={<Config pageId={"config"} />} />

                {/*Rotas de redirecionamento*/}
                <Route path="*" element={<Navigate to={"/dashboard/overview"} />} />
            </Routes>
        </BrowserRouter>
        </Providers>
    )
}