//Default imports
import emailjs from "@emailjs/browser";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//Services
import { editPasswordOther, getRechange } from "../../services/user/put";

//Components
import Button from "../../components/button/button";
import Input from "../../components/input/input";

//Icons
import { AiOutlineLogin, AiOutlineMail } from "react-icons/ai";

//Image
import Logo from "../../img/logo.png";

//Style
import { MdLock } from "react-icons/md";
import toastStyle from "../../data/toast_style";
import "./remember.css";

export default function Remember() {
  const [data , setData] = useState({
    email: "",
  });
  const [isLoging , setIsloging] = useState(false);
  const [state , setState] = useState("SEND");
  const [code ,setCode] = useState("");
  const [valueCode , setValueCode] = useState("");
  const [password , setPassword] = useState("");
  const [confirm , setConfirm] = useState("");
  const [userId , setUserId] = useState("")
  const navigate = useNavigate();

  async function sendCode() {
    const serviceId = "service_ppgz6me";
    const templateId = "template_4cqcxin";
    const publicKey = "a2Gc_oXuvX_Ro53D3"


    setIsloging(true)
    const toastId = toast.loading("Enviando código..." , toastStyle())
    const _send = await getRechange(data.email);
    if(_send.code != 200) {
      setIsloging(false)
      toast.dismiss(toastId)
      return toast.error("Erro ao enviar o código" , toastStyle())
    }
    if(_send.code == 200) {
      setCode(_send?.data?.data);
      setUserId(_send?.data?.userId)
      const templateParams = {
        email: data.email,
        code: _send?.data?.data
      }
      emailjs.send(serviceId , templateId , templateParams , publicKey)
      .then((res) => {
        toast.dismiss(toastId);
        toast.success("Código enviado!" , toastStyle());
        setIsloging(false);
        return setState("CODE")
      })
    }
  }
  async function confirmCode() {
    if(code != valueCode) {
      return toast.error("Código inválido!" , toastStyle())
    }
    toast.success("Código verificado com sucesso!" , toastStyle())
    return setState("PASSWORD")
  }
  async function userPassword() {
    setIsloging(true)
    const toastId = toast.loading("Editando senha..." , toastStyle())
    if(password.length < 6) {
      setIsloging(false)
      toast.dismiss(toastId)
        return toast.error("Senha inválida!" , toastStyle())
    }
    if(password != confirm) { setIsloging(false) ; toast.dismiss(toastId) ; return toast.error("As senhas não conferem." , toastStyle())}
    const _edit = await editPasswordOther(password , userId);
    if(_edit.code != 200) {
      setIsloging(false)
      toast.dismiss(toastId)
      toast.error("Erro ao editar senha!" , toastStyle()) 
    }
    if(_edit.code == 200) {
      setIsloging(false)
      toast.dismiss(toastId)
      toast.success("Senhas editada com sucesso!" , toastStyle()) 
      return navigate("/login")
    }
    }

  
    return (
        <div class="container">
        <div class="left">
          <img className="animation a1" src={Logo} width={"212px"} />
        <div className="horizontal_line_dash"></div>
        <div className="call">
          <h2 class="animation a1">Controle financeiro pessoal,<br/> simples e sem frescura</h2>
        </div>
        <div className="horizontal_line"></div>
          <div class="header_login">
            <h2 class="animation a1" style={{fontSize:"22px"}}>
              {
                {
                  "SEND" : "Recuperar conta",
                  "CODE" : "Recuperar conta",
                  "PASSWORD" : "TROCAR SENHA"
                }[state]
              }
              </h2>

            <h4 class="animation a2">
              {
                {
                  "SEND" : "Informe o e-mail de sua conta, para que possamos enviar um e-mail de verificação para você.",
                  "CODE" : `Enviamos um código de confirmação para \n${data.email}.`,
                  "PASSWORD" : "Troque a sua senha e conecte na sua conta novamente."
                }[state]
              }</h4>
          </div>
          <div className="horizontal_line"></div>
          {state == "SEND" && (
          <div class="form">
            <Input 
            type={"normal"}
            disable={isLoging}
            typeInput={"e-mail"}
            title={"E-mail"}
            placeholder={"Ex: exemple@mail.com"}
            icon={<AiOutlineMail />}
            margin={"15px 0px 10px 0px"}
            searchValue={value => {
                setData({
                  ...data , email: value
                })
            }}
            background={"white"}
            mainStyle={{
              opacity: isLoging ? "0.4" : "1",
              cursor: isLoging ? "not-allowed" : "unset"
            }}
            onKey={value => {
              if(value == "Enter") {
                sendCode()
              }
            }}
            />
            <Button 
            action={() => {sendCode()}}
            text={"Enviar código"}
            type={"default"}
            icon={<AiOutlineLogin />}
            fontSize={"15px"}
            radius={"35px"}
            height={"35px"}
            disable={isLoging}
            />
          </div>
          )}
          {state == "CODE" && (
          <div class="form">
            <Input 
            type={"normal"}
            disable={isLoging}
            title={"Código de confirmação"}
            placeholder={"XXXXXX"}
            icon={<AiOutlineMail />}
            margin={"15px 0px 10px 0px"}
            value={valueCode}
            searchValue={value => {
                setValueCode(value?.toUpperCase())
            }}
            max={6}
            background={"white"}
            mainStyle={{
              opacity: isLoging ? "0.4" : "1",
              cursor: isLoging ? "not-allowed" : "unset"
            }}
            onKey={value => {
              if(value == "Enter") {
                confirmCode()
              }
            }}
            />
            <Button 
            action={() => {confirmCode()}}
            text={"Confirmar"}
            type={"default"}
            icon={<AiOutlineLogin />}
            fontSize={"15px"}
            radius={"35px"}
            height={"35px"}
            disable={isLoging}
            />
          </div>
          )}
          {state == "PASSWORD" && (
          <div class="form">                                
          <Input 
          title={"Senha"}
          placeholder={"********"}
          type={"password"} 
          typeInput={'password'}
          searchValue={(value) => {
              setPassword(value)
          }}
          margin={"0px 0px 15px 0px"}
          value={password}
          icon={<MdLock color="var(--font-thin)" />}
          disable={isLoging}
          mainStyle={{
            opacity: isLoging ? "0.4" : "1",
            cursor: isLoging ? "not-allowed" : "unset"
          }}
          />
          <Input 
          title={"Confirmar senha"}
          placeholder={"********"}
          type={"password"} 
          typeInput={'password'}
          searchValue={(value) => {
              setConfirm(value)
          }}
          disable={isLoging}
          margin={"0px 0px 15px 0px"}
          value={confirm}
          icon={<MdLock color="var(--font-thin)" />}
          mainStyle={{
            opacity: isLoging ? "0.4" : "1",
            cursor: isLoging ? "not-allowed" : "unset"
          }}
          onKey={value => {
            if(value == "Enter") {
              userPassword()
            }
          }}
          />
            <Button 
            action={() => {userPassword()}}
            text={"Trocar senha"}
            type={"default"}
            icon={<AiOutlineLogin />}
            fontSize={"15px"}
            radius={"35px"}
            height={"35px"}
            disable={isLoging}
            />
          </div>
          )}
        </div>
        <div class="right"></div>
      </div>
    )
}