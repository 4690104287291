import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

//Services
import { myUser } from "../services/user/get";

//Pages
import Login from "../pages/login/login";
import Remember from "../pages/remember/remember";
import Singup from "../pages/singup/singup";

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
    const [userLogged, setUserLogged] = useState(null);
    const [isLoading, setLoading] = useState(true);     
  
    useEffect(() => {
      async function myAccount() {
        const token = localStorage.getItem("@SM:Token");
        const id = localStorage.getItem("@SM:Id");
        const _me = await myUser();
        if (_me && _me.code && _me.code == 200) {
          setUserLogged(_me.data);
        } else {
          localStorage.removeItem("@SM:Token");
          localStorage.removeItem("@SM:Id");
        }
  
        setLoading(false);
      }
      myAccount();
    }, []);
  
    if (isLoading) {
      return (
        <>
          <h1></h1>
        </>
      );
    }
  
    if (!userLogged) {
      return (
        <>
          <BrowserRouter>
            <Routes>
            <Route path="/remember" element={<Remember />} />
            <Route path="/singup" element={<Singup />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </BrowserRouter>
        </>
      );
    }
  
    return (
      <AuthContext.Provider value={{ userLogged, setUserLogged }}>
        {children}
      </AuthContext.Provider>
    );
  }