//Defaults
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

//Services
import { editChange } from "../../../../services/user/put";

//Components
import Button from "../../../../components/button/button";
import Input from "../../../../components/input/input";

//Utils
import { moneyFormat } from "../../../../utils/money_format";

//Icons
import { MdClose, MdEdit } from "react-icons/md";

//Style
import CurrencyInput from "react-currency-input-field";
import toastStyle from "../../../../data/toast_style";
import "../overview.css";

export function Edit({setPopup , setReload , revenue , setRevenue}) {
    const [body , setBody] = useState({
        type: "Receita",
        name: "",
        value: 0,
        portion: 1,
        is_received: "Outra",
        date: 0,
        repeat: false,
        fixed: false
    });
    const [isCreating , setIscreating] = useState(false)
    async function reportEdit() {
        const toastid = toast.loading("Editando finança..." , toastStyle())
        const type = body?.type == "Receita" ? "revenue" : body?.type == "Despesa" ? "expense" : "revenue";
        const name = body?.name;
        const value = body?.value * 100;
        const repeat = body?.repeat;
        const portion = parseInt(body?.portion);
        const is_received = body?.is_received == "A receber" ? "pay" : body?.is_received == "Pendente" ? "pending" : body?.is_received == "Não recebida" ? "expired" : "other";
        const date = new Date(body?.date).toISOString();
        const fixed = body?.fixed
        setIscreating(true)
        if(name?.length < 1) {
            toast.dismiss(toastid)
            setIscreating(false)
            return toast.error("Insira um nome válido!" , toastStyle())
        }
        if(value == 0) {
            toast.dismiss(toastid)
            setIscreating(false)
            return toast.error("Insira um valor válido!" , toastStyle())
        }
        if(value == 0) {
            toast.dismiss(toastid)
            setIscreating(false)
            return toast.error("Insira um valor válido!" , toastStyle())
        }
        if(date == 0) {
            toast.dismiss(toastid)
            setIscreating(false)
            return toast.error("Insira uma data válida!" , toastStyle())
        }
        if(portion <= 0) {
            toast.dismiss(toastid)
            setIscreating(false)
            return toast.error("Insira uma parcela válida!" , toastStyle())
        }
        const _create = await editChange({
            type: type,
            name:name,
            value: value,
            repeat: repeat,
            portion: portion,
            is_received: is_received,
            date: date,
            fixed: fixed
        } , revenue?._id);
        if(_create.code != 200) {
            toast.dismiss(toastid)
            setIscreating(false)
            return toast.error("Erro ao editar finança!" , toastStyle())
        }
        if(_create.code == 200) {
            toast.dismiss(toastid)
            setIscreating(false)
            setReload(true)
            setPopup("")
            return toast.success("Finança editada com sucesso!" , toastStyle())
        }
    }
    const handleChange = (value) => {
        const novoValor = value;

    
        // Remove caracteres não numéricos, exceto ponto decimal
        const valorNumerico = novoValor.replace(/[^0-9.]/g, '');
    
        // Formata o valor enquanto o usuário digita
        setBody({
            ...body,
            value: moneyFormat(valorNumerico)
        })
      };
      useEffect(() => {
        const type = revenue?.type == "revenue" ? "Receita" : revenue?.type == "expense" ? "Despesa" : "Receita";
        const received = {
            "pay" : "A receber",
            "pending" : "Pendente",
            "expired" : "Não recebida"
        }[revenue?.is_received]
        setBody({
            type: type,
            name: revenue?.name,
            value: revenue?.value / 100,
            portion: revenue?.portion,
            is_received: received,
            date: revenue?.date,
            repeat: revenue?.repeat,
            fixed: revenue?.fixed
        })
      } , [])
    return (
        <div className="create">
            <div className="top">
                <MdClose cursor={"pointer"} size={"1.5em"} onClick={() => {
                    setRevenue(null)
                    setPopup("")
                }} />
            </div>
            <div className="adscence"></div>
                <div className="p2_form">
                    <div className="form">
                        <h3>Adicionar uma nova finança</h3>
                        <p>Adicione, compare e impulsione as suas finanças mensais</p>
                        <div className="horizontal_line"></div>
                        <div className="select_input" 
                        style={{
                            opacity: isCreating ? "0.4" : "1",
                            cursor: isCreating ? "not-allowed" : "unset"
                          }}
                        >
                            <p>Tipo de finança</p>
                            <select value={body?.type} onChange={(e) => {
                                setBody({
                                    ...body,
                                    type: e.target.value
                                })
                            }}>
                                <option>Receita</option>
                                <option>Despesa</option>
                            </select>
                        </div>
                        <div className="horizontal_line"></div>
                        <Input 
                        type={"normal"}
                        placeholder={"Insira o nome da finança"}
                        title={"Nome"}
                        value={body?.name}
                        background={"#f6f6f6"}
                        searchValue={value => {
                            setBody({
                                ...body,
                                name: value
                            })
                        }}
                        fontWeight={"500"}
                        fontSize={"14px"}
                        margin={"0px 0px 15px 0px"}
                        mainStyle={{
                            opacity: isCreating ? "0.4" : "1",
                            cursor: isCreating ? "not-allowed" : "unset"
                          }}
                        />
                        <div style={{marginBottom: "15px"}}>
                        <p style={{fontSize: '14px' , marginBottom: "5px"}}>Valor</p>
                        <CurrencyInput
                        id="input-example"
                        name="input-name"
                        placeholder="0,00"
                        value={body.value}
                        decimalsLimit={2}
                        onValueChange={(value, name, values) => setBody({...body , value: parseFloat(value)})}
                        style={{
                            width: "100%",
                            height: "40px",
                            border: "1px solid var(--line)",
                            padding: "0px 15px",
                            borderRadius: "5px",
                            backgroundColor: "#f6f6f6",
                            outline: "none",
                            opacity: isCreating ? "0.4" : "1",
                            cursor: isCreating ? "not-allowed" : "unset"
                        }}
                        />
                        </div>
                        <div className="input_row">
                            <div className="select_input gray" 
                            style={{
                                opacity: isCreating ? "0.4" : "1",
                                cursor: isCreating ? "not-allowed" : "unset"
                            }}
                            >
                                <p>Tipo de pagamento</p>
                                <select value={body?.repeat} onChange={(e) => {
                                    if(e.target.value == "Único (Não se repete)") {
                                        setBody({
                                            ...body,
                                            repeat: false
                                        })
                                    }
                                    if(e.target.value == "Parcelado (Se repete)") {
                                        setBody({
                                            ...body,
                                            repeat: true
                                        })
                                    }
                                }}>
                                    <option>Único (Não se repete)</option>
                                    <option>Parcelado (Se repete)</option>
                                </select>
                            </div>
                            {body?.repeat && (
                            <Input 
                            type={"normal"}
                            typeInput={"number"}
                            placeholder={"Numero de Parcelas"}
                            title={"Parcelas"}
                            value={body.portion}
                            background={"#f6f6f6"}
                            searchValue={value => {
                                setBody({
                                    ...body,
                                    portion: value
                                })
                            }}
                            fontWeight={"500"}
                            fontSize={"14px"}
                            mainStyle={{
                                opacity: isCreating ? "0.4" : "1",
                                cursor: isCreating ? "not-allowed" : "unset"
                            }}
                            width={"40%"}
                            />
                            )}
                            {/*
                            <div className="select_input gray">
                                <p>Status da receita</p>
                                <select value={body?.is_received} defaultValue={"Selecionar"} onChange={(e) => {
                                    setBody({
                                        ...body,
                                        is_received: e.target.value
                                    })
                                }}>
                                    <option disabled>Selecionar</option>
                                    <option>A receber</option>
                                    <option>Pendente</option>
                                    <option>Não recebida</option>
                                </select>
                            </div>
                            */}
                        </div>
                        <div className="input_row"
                        style={{
                            opacity: isCreating ? "0.4" : "1",
                            cursor: isCreating ? "not-allowed" : "unset"
                          }}>
                        <div style={{marginTop: "10px"}} className="select_input gray">
                            <p>Data</p>
                            <input value={`${new Date(body?.date).getFullYear()}-${new Date(body?.date).getMonth() + 1 < 10 ? 0 : ""}${new Date(body?.date).getMonth() + 1}-${new Date(body?.date).getDate() < 9 ? 0 : ""}${new Date(body?.date).getDate() + 1}`} type="date"  onChange={(e) => {
                                setBody({
                                    ...body,
                                    date: new Date(e.target.value)
                                })
                            }}/>
                        </div>
                        </div>
                        <div className="select_input gray" 
                            style={{
                                opacity: isCreating ? "0.4" : "1",
                                cursor: isCreating ? "not-allowed" : "unset",
                                marginTop: "10px"
                            }}
                            >
                                <p>Receita Fixa</p>
                                <select value={{
                                    false: "Não",
                                    true: "Sim"
                                }[body?.fixed]} onChange={(e) => {
                                    if(e.target.value == "Sim") {
                                        setBody({
                                            ...body,
                                            fixed: true
                                        })
                                    }
                                    if(e.target.value == "Não") {
                                        setBody({
                                            ...body,
                                            fixed: false
                                        })
                                    }
                                }}>
                                    <option>Sim</option>
                                    <option>Não</option>
                                </select>
                            </div>
                        <Button 
                        action={() => {reportEdit(body)}}
                        text={"Editar Finança"}
                        icon={<MdEdit />}
                        height={"35px"}
                        margin={"15px 0px 0px 0px"}
                        disable={isCreating}
                        />
                    </div>
                </div>
        </div>
    )
}
