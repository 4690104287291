const months = [
    {
        id: 1,
        month: "Janeiro",
        english: "January",
        abbreviation: "Jan",
    },
    {
        id: 2,
        month: "Fevereiro",
        english: "February",
        abbreviation: "Fev",
    },
    {
        id: 3,
        month: "Março",
        english: "March",
        abbreviation: "Mar",
    },
    {
        id: 4,
        month: "Abril",
        english: "April",
        abbreviation: "Abr",
    },
    {
        id: 5,
        month: "Maio",
        english: "May",
        abbreviation: "Mai",
    },
    {
        id: 6,
        month: "Junho",
        english: "Juny",
        abbreviation: "Jun",
    },
    {
        id: 7,
        month: "Julho",
        english: "July",
        abbreviation: "Jul",
    },
    {
        id: 8,
        month: "Agosto",
        english: "August",
        abbreviation: "Ago",
    },
    {
        id: 9,
        month: "Setembro",
        english: "September",
        abbreviation: "Set",
    },
    {
        id: 10,
        month: "Outubro",
        english: "Octuber",
        abbreviation: "Out",
    },
    {
        id: 11,
        month: "Novembro",
        english: "November",
        abbreviation: "Nov",
    },
    {
        id: 12,
        month: "Dezembro",
        english: "December",
        abbreviation: "Dez",
    },
];
const years = [
    2020,
    2021,
    2022,
    2023,
    2024,
]

export { months, years };

