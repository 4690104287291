export function formatPhoneNumber(number) {
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    return number.toString().replace(regex, "($1) $2-$3");
  }


  export function formatNewphone(value , typeN , typeMax) {
    if(!value) return value;
    const phoneNumber = value?.toString().replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if(phoneNumberLength < 2) return phoneNumber;
    if(phoneNumberLength > 2 && phoneNumberLength < typeN) {
        return(`(${phoneNumber.substring(0,2)}) ${phoneNumber.substring(2)}`)
    }
    if(phoneNumberLength > typeN) {
        return(`(${phoneNumber.substring(0,2)}) ${phoneNumber.substring(2,typeN)}-${phoneNumber.substring(typeN,typeMax)}`)
    }
}