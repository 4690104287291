//Default
import { useEffect , useState } from "react";
import { Link } from "react-router-dom";

//Style
import "./button.css"


export default function Button({text , fontSize , fontWeight ,disable, radius ,type, icon , backgroundColor , widht , height, margin , action, link}) {
    return (
        <>
            {link && (
            <Link
            to={link} 
            style={{
                width: widht,
                height: height,
                margin: margin,
                borderRadius: radius ? radius : "80px",
                cursor: disable ? "not-allowed" : "pointer",
                fontSize: fontSize ? fontSize : "16px",
                fontWeight: fontWeight ? fontWeight : "500",
                backgroundColor: backgroundColor && backgroundColor
            }}
            className={type ? `button ${type}` : `button default`}>
                 {text}
                 {icon && icon}
            </Link>
            )}
            {action && (
            <button 
            onClick={() => {if(!disable) return action()}}
            style={{
                width: widht,
                height: height,
                margin: margin,
                borderRadius: radius ? radius : "80px",
                cursor: disable ? "not-allowed" : "pointer",
                fontSize: fontSize ? fontSize : "16px",
                fontWeight: fontWeight ? fontWeight : "500",
                backgroundColor: backgroundColor && backgroundColor
            }}
            className={type ? `button ${type}` : `button default`}>
                 {text}
                 {icon && icon}
            </button>
            )}
        </>
    )
}