//Default imports
import { Toaster } from "react-hot-toast";

//Routes
import Router from "./routes/router";

//Style
import "./styles/default_table.css";
import "./styles/global.css";

function App() {
  return (
    <div className="App">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <Router />
    </div>
  );
}

export default App;
