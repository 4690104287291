//Default imports
import { Player } from "@lottiefiles/react-lottie-player";
import { useEffect, useState } from "react";

//Services
import { deleteReport } from "../../../services/user/delete";
import { myReports } from "../../../services/user/get";

//Components
import AddButton from "../../../components/add_button/addButton";
import Header from "../../../components/header/header";
import TableCard from "../../../components/table_Card/table_card";
import { Create } from "./popups/create";
import { Edit } from "./popups/edit";

//Data
import { months, years } from "../../../data/months";

//Utils
import { moneyFormat } from "../../../utils/money_format";

//Icons
import { BiSolidLike } from "react-icons/bi";
import {
    MdArrowDownward,
    MdArrowForwardIos,
    MdArrowUpward,
    MdCheckCircle,
    MdClose,
    MdDelete,
    MdEdit,
    MdEmail,
    MdOutlineCalendarMonth,
    MdOutlineScreenSearchDesktop,
    MdRemove,
    MdWhatsapp
} from "react-icons/md";
import { PiCalculator, PiWarningOctagonLight } from "react-icons/pi";
import { TbArrowsDownUp } from "react-icons/tb";

//Image
import Calc from "../../../img/boneco.png";

//Style
import { ResponsiveBar } from "@nivo/bar";
import toast from "react-hot-toast";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AdsComponent } from "../../../components/ads_component/ads_component";
import toastStyle from "../../../data/toast_style";
import { editPay } from "../../../services/user/put";
import "../../../styles/create.css";
import "../../../styles/loading.css";
import "./overview.css";

export function Loading() {
    return (
        <div className="loading">
            <Player
            src={"https://lottie.host/dcf47fef-fb92-467b-8aaa-4d06eb64d132/prn2zwjIgB.json"}
            loop
            autoplay
            style={{
                width: "170px"
            }}
            speed={2}
            />
        </div>
    )
}

export function Table({title , data , type ,  setReload , setRevenue}) {
    const navigate = useNavigate();
    async function pay(value , id) {
        const toastId = toast.loading("Alterando finança..."  , toastStyle());
        const _pay = await editPay(value , id);
        if(_pay.code == 200) {
            toast.dismiss(toastId)
            if(type == "expired") {
                toast.success("Finança retirada!" , toastStyle());
            }
            if(type == "paga") {
                toast.success("Finança retirada!" , toastStyle());
            }
           return setReload(true)
        }
        if(_pay.code != 200) {
            toast.dismiss(toastId)
            toast.error("Erro ao deletar." , toastStyle())
            return setReload(true)
        }
    }
    async function reportDelete(id) {
        const toastId = toast.loading("Deletando finança..."  , toastStyle())
        const _delete = await deleteReport(id);
        if(_delete.code == 200) {
            toast.dismiss(toastId)
           toast.success("Finança deletada!" , toastStyle());
           return setReload(true)
        }
        if(_delete.code != 200) {
            toast.dismiss(toastId)
            toast.error("Erro ao deletar." , toastStyle())
            return setReload(true)
        }
    }

    return(
        <div className="table_container">
            {data?.length != 0 && (
                <>
                <div className="title_table">
                    <h3>{title}</h3>
                    
                    <button onClick={() => {navigate("/dashboard/reports")}}>
                        Ir para relatório
                        <MdArrowForwardIos />
                    </button>
                </div>
                <table className="default_table">
                    <tr className={`first_line ${type}`}>
                        <th>FIXA</th>
                        <th>NOME</th>
                        <th>VALOR</th>
                        <th>PARCELAS</th>
                        <th>DATA</th>
                        <th>RECORRENTE</th>
                        {type == "expense" && (
                        <th>STATUS</th>
                        )}
                        <th></th>
                    </tr>
                    {data?.sort((a,b) => a?.created_date > b?.created_date ? -1 : 1)?.slice(0,4).map((res  , i ) => {
                        return (
                            <tr className={`line ${type == "expense"&&res.is_received}`}>
                                <td>{res?.fixed ? <MdCheckCircle color="var(--main-green)" size={"1.5em"} /> : <MdRemove color="var(--font-thin)" size={"1.5em"} />}</td>
                                <td>{res.name}</td>
                                <td>{moneyFormat(res.value / 100)}</td>
                                <td>{res.portion}</td>
                                <td>{new Date(res.date).toLocaleDateString()}</td>
                                <td>{res.repeat ? "Sim" : "Não"}</td>
                                {type == "expense" && (
                                <td>
                                    {
                                        {
                                            "pay" : "Pago",
                                            "expired" : "A pagar",
                                        }[res.is_received || "Outro"]
                                    }</td>
                                    )}
                                <td >
                                    
                                    <div className="tools">
                                        {type == "expense" && res.is_received == "expired" ? (
                                    <div className="button_tool like" onClick={() => {
                                        pay("pay" , res?._id)
                                        }}>
                                            Pagar
                                            <AiFillLike />
                                        </div>
                                        ) : null}
                                    {type == "expense" && res.is_received == "pay" ? (
                                    <div className="button_tool dislike" onClick={() => {
                                            pay("expired" , res?._id)
                                        }}>
                                            Retirar
                                            <AiFillDislike />
                                        </div>
                                        ) : null}
                                    <div className="button_tool" onClick={() => {
                                        setRevenue(res)
                                    }}>
                                            <MdEdit />
                                        </div>
                                        <div className="button_tool delete" onClick={() => {
                                            reportDelete(res?._id)
                                        }}>
                                            <MdDelete />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </table>
                </>
            )}
            {data?.length == 0 && (
                <div style={{display: "flex",flexDirection: 'column' , justifyContent: "center" , alignItems: "center"}}>
                    <MdOutlineScreenSearchDesktop color="var(--line)" size={"3em"} />
                    <h2 style={{fontSize: "18px", fontWeight:"500" , color: "var(--line)"}}>
                        Nenhuma{" "}
                        {type == "profit" && "receita"}{" "}
                        {type == "expense" && "despesa"}{" "}
                        encontrada
                    </h2>
                </div>
            )}
        </div>
    )
}

export function ListCards({title , data , type}) {
    return (
        <div className="card_list">
            <div className="horizontal_line" ></div>
            <div className="title_list">
                <div className="p1">
                {type && (
                <div className={`icon_container ${type == "revenue" ? "green" : "red"}`}>
                    {type == "revenue" && < MdArrowUpward size={"1.5em"} />}
                    {type == "expense" && < MdArrowDownward size={"1.5em"}  />}
                </div>
                )}
                <h3>{title}</h3>
                </div>
            </div>
            <div className="horizontal_line"></div>
            {data?.length == 0 && (
                <div style={{display: "flex",flexDirection: 'column' , justifyContent: "center" , alignItems: "center"}}>
                    <MdOutlineScreenSearchDesktop color="var(--line)" size={"3em"} />
                    <h2 style={{fontSize: "18px", fontWeight:"500" , color: "var(--line)"}}>
                        Nenhuma{" "}
                        {type == "profit" && "receita"}{" "}
                        {type == "expense" && "despesa"}{" "}
                        encontrada
                    </h2>
                </div>
            )}
            {data?.length != 0 && (
            <div className="horizontal_list">
            {data?.map((res , i) => {
                return (
                <TableCard 
                    name={res?.name}
                    value={parseInt(res?.value)}
                    parts={parseInt(res?.portion)}
                    date={res?.date}
                    repeat={res?.repeat}
                    pay={res?.is_received}
                />
                )
            })}
            </div>
            )}
        </div>
    )
}




export default function Overview({pageId}) {
    const [apidata , setApidata] = useState([])
    const [status , setStatus] = useState(true);
    const [selectmonth , setSelectmonth] = useState(new Date().getMonth() + 1);
    const [selectyear , setSelectYear] = useState(new Date().getFullYear())
    const [positivedata , setPositivedata] = useState([]);
    const [negativedata , setNegativedata] = useState([]);
    const [total , setTotal] = useState(0);
    const [revenue , setRevenue] = useState(0);
    const [expense , setExpense] = useState(0);
    const [balance , setBalance] = useState(0);
    const [percent , setPercent] = useState(0);
    const [chart , setChart] = useState([]);
    const [popup , setPopup] = useState("");
    const [reload , setReload] = useState(false);
    const [loading , setLoading] = useState(true);
    const [selectrevenue , setSelectRevenue] = useState(null)

    function enterNumbers(numero, limiteInferior, limiteSuperior) {
        return numero >= limiteInferior && numero <= limiteSuperior;
      }


    function filtering(data) {
        setPositivedata([]);
        setNegativedata([]);
        var totals = 0;
        var revalue = 0;
        var expenses = 0;
        var balances = 0;
        var percents = 0;
        setTotal(0);
        setRevenue(0);
        setExpense(0);
        setBalance(0);
        setPercent(0);
        const newData = [];
        for(let i = 0 ; i < data?.length ; i++) {
            const monthl = new Date(data[i]?.date).getMonth();
            const year = new Date(data[i]?.date).getFullYear();
            const _find = newData?.sort((a , b) => a.realDate <= b.realDate ? -1 : 1).find((res) => res?.month === monthl)
            if(_find) {
                if(data[i]?.type == "revenue") {
                    _find.Receita += parseInt(data[i].value / 100 , 10)
                }
                if(data[i]?.type == "expense" && data[i]?.is_received == "expired" ) {
                    _find.Despesa += parseInt(data[i].value / 100 , 10)
                }
            } else {
                newData.push({
                    realDate: data[i]?.date,
                    month: monthl,
                    monthMask: `${months[monthl]?.abbreviation} (${year})`,
                    Receita: data[i]?.type === "revenue" ? parseInt((data[i]?.value / 100), 10) : 0,
                    revenueColor: "#0180FF",
                    Despesa: data[i]?.type === "expense" && data[i]?.is_received == "expired" ? parseInt(data[i]?.value / 100, 10) : 0,
                    expenseColor: "#0180FF",
                });
            }
        };
        setChart(newData)
        for(var newdata of data) {
            const month = new Date(newdata?.date).getMonth() + 1;
            const year = new Date(newdata?.date).getFullYear();
            const withPortion = new Date(newdata?.date).getMonth() + newdata?.portion
            if(!newdata.fixed) {
                if(newdata.portion != 1) {
                    if(enterNumbers(selectmonth , month , withPortion)  && year == selectyear && newdata.type == "revenue") {
                        revalue = revalue + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0));
                        setRevenue(revalue)
                    }
                    if(enterNumbers(selectmonth , month , withPortion)  && year == selectyear && newdata.type == "expense" && newdata?.is_received == "expired") {
                        expenses = expenses + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0))
                        setExpense(expenses)
                    }
                    if(enterNumbers(selectmonth , month , withPortion)  && year == selectyear) {
                        totals = totals + newdata.value;
                        balances = revalue - expenses;
                        setTotal(totals)
                        setBalance(balances);
                        setPercent(percents)
                    }
                }
                if(newdata.portion == 1) {
                    if(month == selectmonth  && year == selectyear && newdata.type == "revenue") {
                        revalue = revalue + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0));
                        setRevenue(revalue)
                    }
                    if(month == selectmonth  && year == selectyear && newdata.type == "expense" && newdata?.is_received == "expired") {
                        expenses = expenses + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0))
                        setExpense(expenses)
                    }
                    if(month == selectmonth  && year == selectyear) {
                        totals = totals + newdata.value;
                        balances = revalue - expenses;
                        setTotal(totals)
                        setBalance(balances);
                        setPercent(percents)
                    }
                }
            }
            if(newdata.fixed) {
                    if(newdata.type == "revenue") {
                        revalue = revalue + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0));
                        setRevenue(revalue)
                    }
                    if( newdata.type == "expense" && newdata?.is_received == "expired") {
                        expenses = expenses + newdata.value
                        percents = Number(((expenses / revalue) * 100).toFixed(0))
                        setExpense(expenses)
                    }
                        totals = totals + newdata.value;
                        balances = revalue - expenses;
                        setTotal(totals)
                        setBalance(balances);
                        setPercent(percents)
                    

            }
        }

        data.filter((res) => {
            const month = new Date(res?.date).getMonth() + 1;
            const withPortion = new Date(res?.date).getMonth() + res?.portion
            const year = new Date(res?.date).getFullYear();
            if(res?.portion == 1 && !res?.fixed) {
                if(month == selectmonth && year == selectyear && res.type == "revenue") {
                    setPositivedata(positivedata => [...positivedata , res]);
                }
                if(month == selectmonth && year == selectyear && res.type == "expense") {
                    setNegativedata(negativedata => [...negativedata , res])
                }
            }
            if(res?.portion != 1 && !res?.fixed) {
                if(enterNumbers(selectmonth , month , withPortion) && year == selectyear && res.type == "revenue") {
                    setPositivedata(positivedata => [...positivedata , res]);
                }
                if(enterNumbers(selectmonth , month , withPortion) && year == selectyear && res.type == "expense" ) {
                    setNegativedata(negativedata => [...negativedata , res])
                }
            }
            if(res?.fixed) {
                if(res.type == "revenue") {
                    setPositivedata(positivedata => [...positivedata , res]);
                }
                if(res.type == "expense" ) {
                    setNegativedata(negativedata => [...negativedata , res])
                }
            }
        })
    };


    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const _get = await myReports();
            setApidata(_get.data?.data);
            if(_get.data?.data.length != 0) {
                filtering(_get.data?.data)
            }   
            setTimeout(() => {
                setLoading(false)
            } , 1000)
            setReload(false)
        }
        fetchData()
    } , [reload , setReload]);


    useEffect(() => {
        if(apidata.length != 0) {
            filtering(apidata)
        }
    } , [selectmonth , selectyear]);


    useEffect(() => {
        if(balance < 0) {
            setStatus(false)
        };
        if(balance >= 0) {
            setStatus(true)
        }
    } , [balance]);
    useEffect(() => {
        if(selectrevenue != null) {
        setPopup("EDIT")
        }
    } , [selectrevenue])
    

    return (
        <div className="overview" style={{
            overflowY: popup == "EDIT" || popup == "CREATE" ? "hidden" : "auto"
        }}>
            {loading && <Loading />}
            {popup == "CREATE" && <Create setPopup={setPopup} setReload={setReload} />}
            {popup == "EDIT" && <Edit setRevenue={setSelectRevenue} revenue={selectrevenue} setPopup={setPopup} setReload={setReload} />}
            {popup == "SEND" && (
                <div className="send_background">
                    <div className="send_content">
                        <div className="close_top">
                        <h3>Compartilhar sistema</h3>
                            <MdClose size={"1.2em"} cursor={"pointer"} onClick={() => {setPopup("")}} />
                        </div>
                        <div className="horizontal_line"></div>
                        <div className="social_row">
                            <a target="_blank" href="https://wa.me/?text=Gerencie%20as%20suas%20finan%C3%A7as%20de%20maneira%20simples%20e%20sem%20frescura%20com:%0Ahttps://app.simplesmoneybr.com.br" className="social_container">
                                WhatsApp
                                <MdWhatsapp />
                            </a>
                            <a target="_blank" href="mailto:?subject=&body=Gerencie%20as%20suas%20finan%C3%A7as%20de%20maneira%20simples%20e%20sem%20frescura%20com%3A%0Ahttps%3A%2F%2Fapp.simplesmoneybr.com.br" className="social_container">
                                E-mail
                                <MdEmail />
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <AddButton setpopup={setPopup} />
            <Header pageId={pageId} />
            <div className="balance_bar">
                <div className="balance_limitation">
                    <div className="status">
                        <div className={`hand_icon ${status ? "positive" : "negative"}`}>
                            <BiSolidLike 
                            size={"1.4em"}
                            style={{
                                transform: status ? "rotate(0deg)" : "rotate(180deg)"
                            }}
                            />
                        </div>
                        <div className="text_status">
                            <p>O seu saldo está:</p>
                            <h3 className={`state ${status ? "positive" : "negative"}`}>
                                {status ? "Positivo" : "Negativo"}
                            </h3>
                        </div>
                    </div>
                    <div className="share">
                        <button onClick={() => {setPopup("SEND")}}>
                            Compartilhar sistema
                        </button>
                    </div>
                </div>
            </div>
            <main className="content">
                <div className="left_tables">
                    <div className="left_limitation">
                        <div className="title_left">
                            <div className="calculator">
                                <div className="icon_container">
                                    <PiCalculator size={"1.8em"} />
                                </div>
                                <div className="texts">
                                    <h3>Visão geral</h3>
                                    <p>Calculadora financeira pessoal</p>
                                </div>
                            </div>
                            <div className="filters">
                                <div className="select_input">
                                    <MdOutlineCalendarMonth size={"1.2em"} />
                                    <select onChange={(e) => {
                                        months.filter((res) => {
                                            if(res.month == e.target.value) {
                                                setSelectmonth(res.id)
                                            }
                                        })
                                    }} value={months[selectmonth - 1]?.month}>
                                        {months?.map((res , i) => {
                                            return (
                                                <option key={res.id}>{res.month}</option>
                                            )
                                        })}
                                    </select>
                                    <TbArrowsDownUp />
                                </div>
                                <p>De</p>
                                <div className="select_input">
                                    <MdOutlineCalendarMonth size={"1.2em"} />
                                    <select onChange={(e) => {
                                        setSelectYear(e.target.value)
                                    }} value={selectyear}>
                                        {years?.map((res , i) => {
                                            return (
                                                <option key={res}>{res}</option>
                                            )
                                        })}
                                    </select>
                                    <TbArrowsDownUp />
                                </div>
                            </div>
                        </div>
                        <div className="table_1">
                            <ListCards title={"Receitas"} data={positivedata} type={"revenue"} />
                            <Table setRevenue={setSelectRevenue} setReload={setReload} title={"Receita do mês"} data={positivedata} type={"profit"} />
                        </div>
                        <div className="table_2">
                            <ListCards title={"Despesas"} data={negativedata} type={"expense"} />
                            <Table setRevenue={setSelectRevenue}  setReload={setReload} title={"Despesas do mês"} data={negativedata} type={"expense"} />
                        </div>

                        <div
                            style={{
                                width: "100%",
                                height: "130px",
                                borderRadius: "15px",
                                backgroundColor: "var(--line)",
                                marginTop: "20px"
                            }}
                        >
                                                        <AdsComponent 
                                dataAdSlot="5535932280"
                            />
                        </div>

                    </div>
                </div>
                <div className="right_overview">
                    <div className="percent">
                        <img src={Calc}/>
                        <div className="content_percent">
                            <h3>Porcentagem Mensal</h3>
                            <p>Adicione, compare e impulsione as suas finanças mensais</p>
                            <div className="horizontal_line"></div>
                            <div className="bar_per">
                                <div className="percent_bar">
                                    <div className="actual_percent" style={{
                                        width: `${percent}%`,
                                        justifyContent: percent < 5 || !isFinite(percent) ? "flex-start" : "flex-end"
                                    }}>
                                        <div className="container_relative"></div>
                                        <div className="percent_container">
                                        {isFinite(percent) ?  
                                        percent : 0}%
                                        </div>
                                    </div>
                                    {/*<h3
                                    style={{
                                        color: percent < 10 ? "var(--font-thin)": "white"
                                    }}
                                >-{moneyFormat(expense / 100)}</h3>*/}
                                </div>
                                <h4>{moneyFormat(revenue / 100)}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="table_balance">
                        <div className="table_container white">
                            <div className="title_table">
                                <PiWarningOctagonLight size={"1.2em"} />
                                <h3>Resumo</h3>
                            </div>
                            <table className="default_table">
                                <tr className={`first_line`}>
                                    <th>RECEITA</th>
                                    <th>DESPESAS</th>
                                    <th>SALDO</th>
                                </tr>
                                <tr>
                                    <td>{moneyFormat(revenue / 100)}</td>
                                    <td>{moneyFormat(expense / 100)}</td>
                                    <td
                                    style={{
                                        fontWeight: "700",
                                        color: balance < 0 ? "red" : "var(--green)"
                                    }}
                                    >{moneyFormat(balance / 100)}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="percent_resume">
                            <div className="text_resume">
                                <p>Saldo restante</p>
                                <h4>{moneyFormat(balance / 100)}</h4>
                            </div>
                            <div style={{
                                borderColor: percent > 100 ? "red" : "white",
                                color: percent > 100 ? "red" : "white"
                            }} className="circle_percent">
                            {isFinite(percent) ?  
                                percent : 0}%
                            </div>
                        </div>
                    </div>
                    <div className="chart_container">
                    <h3>RECEITA X DESPESAS</h3>
                    <div className="horizontal_line"></div>
                    <ResponsiveBar
                        data={chart?.slice(0, 11)}
                        keys={["Receita", "Despesa"]}
                        indexBy="monthMask"
                        groupMode="grouped"
                        margin={{ top: 20, right: 10, bottom: 80, left: 10 }}
                        padding={0.3}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={(data) => {
                            if(data.id == "Receita") {
                               return  "#537F25"
                            }
                            if(data.id == "Despesa") {
                                return "#EB5757"
                            }
                        }}
                        axisTop={null}
                        theme={{
                            textColor: "var(--main-green)",
                            fontSize: "20px",
                            grid: {
                            line: {
                                opacity: .7,
                                color: 'var(--line)',
                            },
                            },
                        }}
                        labelTextColor="#ffffff"
                        axisRight={null}
                        labelSkipWidth={16}
                        labelSkipHeight={16}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        
                        barAriaLabel={(e) =>
                            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
                        }
                    />
                    </div>
                    <div
                            style={{
                                width: "100%",
                                height: "130px",
                                borderRadius: "15px",
                                backgroundColor: "var(--line)",
                                marginTop: "20px"
                            }}
                        >
                                                        <AdsComponent 
                                dataAdSlot="5535932280"
                            />
                        </div>
                </div>
            </main>
        </div>
    )
}