//Default imporst

//Api
import environment from "../../environment/environment";

//Utils
import translate from "../../utils/translate";

//Authenticação de usuário
export async function authenticate(body) {
    const _auth = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/auth`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }
    );
    const _data = await _auth.json();
    if(_auth.status != 200) {
        return {
            code: _auth.status,
            message: translate(_data.message)
        }
    }
    if(_auth.status == 200) {
        return {
            code: _auth.status,
            data: _data
        }
    }
}
//Criar de usuário
export async function createUser(body) {
    const _auth = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }
    );
    const _data = await _auth.json();

    if(_auth.status != 200) {
        return {
            code: _auth.status,
            message: translate(_data.message)
        }
    }
    if(_auth.status == 200) {
        return {
            code: _auth.status,
            data: _data
        }
    }
}
export async function createReport(body) {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");
    const _auth = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/create/report/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            },
            body: JSON.stringify(body)
        }
    );
    const _data = await _auth.json();
    if(_auth.status != 200) {
        return {
            code: _auth.status,
            message: translate(_data.message)
        }
    }
    if(_auth.status == 200) {
        return {
            code: _auth.status,
            data: _data
        }
    }
}