
//Api
import environment from "../../environment/environment";

//Utils
import translate from "../../utils/translate";

//Criar de usuário
export async function createUser(body) {
    const _auth = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }
    );
    const _data = await _auth.json();
    if(_auth.status != 200) {
        return {
            code: _auth.status,
            message: translate(_data.message)
        }
    }
    if(_auth.status == 200) {
        return {
            code: _auth.status,
            data: _data
        }
    }
}

export async function editUser(body) {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");

    const _edit = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/user/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token" : token
            },
            body: JSON.stringify(body)
        }
    );
    const _data = await _edit.json();
    if(_edit.status != 200) {
        return {
            code: _edit.status,
            message: translate(_data.message)
        }
    }
    if(_edit.status == 200) {
        return {
            code: _edit.status,
            data: _data
        }
    }
}
export async function editPassword(password) {
    const token = localStorage.getItem("@SM:Token");
    const id = localStorage.getItem("@SM:Id");

    const _edit = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/password/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token" : token
            },
            body: JSON.stringify({password: password})
        }
    );
    const _data = await _edit.json();
    if(_edit.status != 200) {
        return {
            code: _edit.status,
            message: translate(_data.message)
        }
    }
    if(_edit.status == 200) {
        return {
            code: _edit.status,
            data: _data
        }
    }
}
export async function editPasswordOther(password , id) {

    const _edit = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/password/rechange/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({password: password})
        }
    );
    const _data = await _edit.json();
    if(_edit.status != 200) {
        return {
            code: _edit.status,
            message: translate(_data.message)
        }
    }
    if(_edit.status == 200) {
        return {
            code: _edit.status,
            data: _data
        }
    }
}

export async function getRechange(email) {

    const _edit = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/rechange`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({email: email})
        }
    );
    const _data = await _edit.json();
    if(_edit.status != 200) {
        return {
            code: _edit.status,
            message: translate(_data.message)
        }
    }
    if(_edit.status == 200) {
        return {
            code: _edit.status,
            data: _data
        }
    }
}
export async function editChange(body , id) {
    const token = localStorage.getItem("@SM:Token");
    const _edit = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/report/edit/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token" : token
            },
            body: JSON.stringify(body)
        }
    );
    const _data = await _edit.json();
    if(_edit.status != 200) {
        return {
            code: _edit.status,
            message: translate(_data.message)
        }
    }
    if(_edit.status == 200) {
        return {
            code: _edit.status,
            data: _data
        }
    }
}
export async function editPay(value , id) {
    const token = localStorage.getItem("@SM:Token");
    const _edit = await fetch(
        `${environment.DB_CONNECT}/${environment.API_VERSION}/pay/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token" : token
            },
            body: JSON.stringify({
                type: value
            })
        }
    );
    const _data = await _edit.json();
    if(_edit.status != 200) {
        return {
            code: _edit.status,
            message: translate(_data.message)
        }
    }
    if(_edit.status == 200) {
        return {
            code: _edit.status,
            data: _data
        }
    }
}