//Default
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

//Contexts
import { AuthContext } from "../../../providers/authProvider";

//Service
import { deleteUser } from "../../../services/user/delete";
import { myUser } from "../../../services/user/get";
import { editPassword, editUser } from "../../../services/user/put";

//Components
import Button from "../../../components/button/button";
import Header from "../../../components/header/header";
import Input from "../../../components/input/input";
import { Loading } from "../overview/overview";

//Icons
import { MdAccountCircle, MdClose, MdLock } from "react-icons/md";

//Images
import Delete from '../../../img/delete.png';

//Style
import { AdsComponent } from "../../../components/ads_component/ads_component";
import toastStyle from "../../../data/toast_style";
import "./config.css";

async function userDelete() {
    const _delete = await deleteUser();
    localStorage.removeItem("@SM:Token");
    localStorage.removeItem("@SM:Id");
    return window.location.href = "/login"
}

export function ConfirmPopup({setpopup}) {
    return (
        <div className="popup_background">
            <div className="popup_container">
                <img src={Delete} style={{
                    filter: "grayscale(1)",
                    opacity: "0.5"
                }} />
                <h3>Deletar conta!</h3>
                <p>Você tem certeza que deseja deletar a sua conta?</p>
                <div className="horizontal_line"></div>
                <div className="button_row">
                <Button 
                action={() => {setpopup("")}}
                text={"Cancelar"}
                height={"35px"}
                backgroundColor={"var(--font-thin)"}
                widht={"100%"}
                />
                <Button 
                action={() => {userDelete()}}
                text={"Deletar"}
                height={"35px"}
                widht={"100%"}
                backgroundColor={"red"}
                />
                </div>
            </div>
        </div>
    )
}

export default function Config({pageId}) {
    const [originaluser , setOriginaluser] = useState()
    const [user , setUser] = useState();
    const [reload , setReload] = useState(false);
    const [password , setPassword] = useState("");
    const [confirm , setConfirm] = useState("");
    const [popup ,setPopup] = useState('');
    const {userLogged} = useContext(AuthContext)
    const [loading , setLoading] = useState(true);
    const [isEditing , setIsEditing] = useState(false);
    const [passEditing , setPassEditing] = useState(false)
    useEffect(() => {
        async function myAccount() {
          const token = localStorage.getItem("@SM:Token");
          const id = localStorage.getItem("@SM:Id");
          const _me = await myUser();
          if (_me && _me.code && _me.code == 200) {
            setOriginaluser({
                ...user,
                name: _me?.data?.data?.name,
                email: _me?.data?.data?.email,
            })
            setUser({
                ...user,
                name: _me?.data?.data?.name,
                email: _me?.data?.data?.email,
            })
            setLoading(false)
          }
        }
        myAccount();
      }, [reload]);
      async function userEdit() {
        const toastId = toast.loading("Editando informações..." , toastStyle())
        setReload(true);
        setIsEditing(true)
        const _edit = await editUser(user);
        if(_edit.code == 200) {
            toast.dismiss(toastId)
            setIsEditing(false)
            setReload(false)
            return toast.success("Usuário editado com sucesso!" , toastStyle()) 
        }
        toast.dismiss(toastId)
        setIsEditing(false)
        setReload(false)
      }
      async function userPassword() {
        const toastId = toast.loading("Editando senha..." , toastStyle())
        setPassEditing(true)
        setReload(false);
        if(password.length < 6) {
            toast.dismiss(toastId)
            setPassEditing(false)
            return toast.error("Senha inválida!" , toastStyle())
        }
        if(password != confirm){setPassEditing(false);toast.dismiss(toastId); return toast.error("As senhas não conferem." , toastStyle())}
        const _edit = await editPassword(password);
        if(_edit.code == 200) {
            toast.dismiss(toastId)
            setPassEditing(false)
            setReload(false)
            return toast.success("Senhas editada com sucesso!" , toastStyle()) 
        }
        toast.dismiss(toastId)
        setPassEditing(false)
        setReload(false)
        }
    
    return (
        <div className="config">
            {loading && <Loading />}
            {popup == "CONFIRM" && <ConfirmPopup setpopup={setPopup} />}
            <Header pageId={pageId} />
            <div className="config_content">
                <div className="config_limitation">
                    <div className="side_bar">
                        <div className="title">
                            <p>Configurações</p>
                        </div>
                        <div className="list">
                            <div className="list_item">
                                <div className="title_item">
                                    <MdAccountCircle  size={"1.5em"} />
                                    <p>Conta</p>
                                </div>
                                <p>Gerencie sua conta de forma facíl e rapída
                                e atualize suas informações.</p>
                            </div>
                            <div className="list_item" onClick={() => {
                                    setPopup("CONFIRM")
                                }}>
                                <div className="title_item">
                                    <MdClose size={"1.5em"} color="red" />
                                    <p>Deletar conta</p>
                                </div>
                                <p>Depois de deletada a conta não poderá 
                                ser recuperada.</p>
                            </div>
                        </div>
                    </div>
                    <div className="p2_edit">
                        <div className="edit_config">
                            <h3>Perfil de usuário</h3>
                            <p>Edite informações básicas sobre o seu perfil.</p>
                            <div className="horizontal_line"></div>
                            <Input 
                            title={"Nome"}
                            placeholder={"Insira o seu nome"}
                            type={"normal"} 
                            typeInput={'text'}
                            searchValue={(value) => {
                                setUser({
                                    ...user,
                                    name: value
                                })
                            }}
                            disable={isEditing}
                            margin={"0px 0px 15px 0px"}
                            value={user?.name}
                            mainStyle={{
                                opacity: isEditing ? "0.4" : "1",
                                cursor: isEditing ? "not-allowed" : "unset"
                              }}
                            />
                            <Input 
                            title={"Email"}
                            placeholder={"Insira o seu email"}
                            type={"normal"} 
                            typeInput={'text'}
                            searchValue={(value) => {
                                setUser({
                                    ...user,
                                    email: value
                                })
                            }}
                            disable={isEditing}
                            margin={"0px 0px 15px 0px"}
                            value={user?.email}
                            mainStyle={{
                                opacity: isEditing ? "0.4" : "1",
                                cursor: isEditing ? "not-allowed" : "unset"
                              }}
                            />
                            <div className="horizontal_line"></div>
                            <div className="tool_button">
                                <Button 
                                action={() => {
                                    setUser(originaluser)
                                }}
                                text={"Resetar"}
                                widht={"100px"}
                                height={"35px"}
                                backgroundColor={"var(--font-thin)"}
                                />
                                <Button 
                                action={() => {
                                    userEdit()
                                }}
                                text={"Salvar"}
                                widht={"100px"}
                                height={"35px"}
                                disable={isEditing}
                                />
                            </div>
                        </div>
                        <div className="edit_config">
                            <h3>Segurança</h3>
                            <p>Edite as suas senhas de maneira fácil.</p>
                            <div className="horizontal_line"></div>
                            <div className="input_row">
                                <Input 
                                title={"Senha"}
                                placeholder={"********"}
                                type={"password"} 
                                typeInput={'password'}
                                searchValue={(value) => {
                                    setPassword(value)
                                }}
                                margin={"0px 0px 15px 0px"}
                                value={password}
                                icon={<MdLock color="var(--font-thin)" />}
                                disable={passEditing}
                                mainStyle={{
                                    opacity: passEditing ? "0.4" : "1",
                                    cursor: passEditing ? "not-allowed" : "unset"
                                  }}
                                />
                                <Input 
                                title={"Confirmar senha"}
                                placeholder={"********"}
                                type={"password"} 
                                typeInput={'password'}
                                searchValue={(value) => {
                                    setConfirm(value)
                                }}
                                margin={"0px 0px 15px 0px"}
                                value={confirm}
                                icon={<MdLock color="var(--font-thin)" />}
                                disable={passEditing}
                                mainStyle={{
                                    opacity: passEditing ? "0.4" : "1",
                                    cursor: passEditing ? "not-allowed" : "unset"
                                  }}
                                />
                            </div>
                            <div className="tool_button">
                                <Button 
                                action={() => {userPassword()}}
                                text={"Salvar"}
                                widht={"100px"}
                                height={"35px"}
                                disable={passEditing}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "160px",
                                borderRadius: "15px",
                                backgroundColor: "var(--line)",
                                marginTop: "20px"
                            }}
                        >
                                                        <AdsComponent 
                                dataAdSlot="5535932280"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}