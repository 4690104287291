//Icon
import {
    MdDangerous,
    MdMoney, MdPending, MdWarning
} from "react-icons/md"

//Style
import "../../styles/default_table.css"

export default function TableCard({_id , name , value , parts , date , repeat , pay}) {
 return (
    <div key={_id} className="table_card">
        <h3>{name}</h3>
        <p className="value_card">R${(value / 100 / parts).toFixed(2).toString().replace("." , ",")}</p>
        <p className="date">{new Date(date).toLocaleDateString()}</p>
        <p>Recorrente: {repeat ? "Sim" : "Não"}</p>
        <div className="horizontal_line" style={{margin: "3px 0px"}}></div>
        <div className="status_card">
            <div className={`pay_icon ${pay}`}>
            {
                    {
                        "pay" : <MdMoney size={"1.3em"} />,
                        "pending": <MdWarning size={"1.3em"} />,
                        "expired" : <MdDangerous size={"1.3em"} />,
                        "other" : <MdPending size={"1.3em"} />
                    }[pay || "Outro"]
                }
            </div>
            <h4 className={`status_text ${pay}`}>
                {
                    {
                        "pay" : "A receber",
                        "pending": "Pendente",
                        "expired" : "Não recebido",
                        "other" : "Outro"
                    }[pay || "Outro"]
                }
            </h4>
        </div>
    </div>
 )
}